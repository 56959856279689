/**
 * Calculates a 4-digit verification code based on a SHA-256 hash of the input Base64 string.
 *
 * @param {string} hashString - The input string, expected to be Base64-encoded.
 * @returns {Promise<string>} - A 4-digit verification code.
 */
const calculateVcode = async (hashString) => {
    // Validate input
    if (typeof hashString !== "string") {
        throw new Error("Input must be a Base64-encoded string.");
    }

    try {
        // Step 1: Decode the Base64 string into bytes
        const binaryString = atob(hashString);
        const bytes = Uint8Array.from(binaryString, (char) => char.charCodeAt(0));

        // Step 2: Compute SHA-256 hash of the decoded bytes
        const hashBuffer = await crypto.subtle.digest("SHA-256", bytes);
        const hashArray = Array.from(new Uint8Array(hashBuffer));

        // Step 3: Extract the last two bytes from the hash
        const lastByte1 = hashArray[hashArray.length - 2];
        const lastByte2 = hashArray[hashArray.length - 1];

        // Combine the two bytes into a single number (Big Endian)
        const combined = (lastByte1 << 8) | lastByte2;

        // Step 4: Convert the number to a string
        let strCode = combined.toString();

        // Step 5: Ensure the code is 4 digits by padding with zeros or truncating
        if (strCode.length < 4) {
            strCode = "0".repeat(4 - strCode.length) + strCode;
        } else if (strCode.length > 4) {
            strCode = strCode.slice(-4);
        }

        return strCode;
    } catch (error) {
        console.error("Error calculating verification code:", error);
        return "0000"; // Fallback code in case of error
    }
};

export default calculateVcode;
