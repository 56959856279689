/**
 * Converts a string to an ArrayBuffer.
 * @param {string} str - The input string.
 * @returns {Uint8Array} - The resulting Uint8Array.
 */
const stringToArrayBuffer = (str) => {
    const encoder = new TextEncoder();
    return encoder.encode(str);
};

/**
 * Converts an ArrayBuffer to a Base64 string.
 * @param {ArrayBuffer} buffer - The input ArrayBuffer.
 * @returns {string} - The Base64 encoded string.
 */
const arrayBufferToBase64 = (buffer) => {
    const bytes = new Uint8Array(buffer);
    let binary = "";
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    return window.btoa(binary);
};

const hashSHA512 = async (inputString) => {
    try {
        // Convert the input string to an ArrayBuffer
        const data = stringToArrayBuffer(inputString);

        // Perform the SHA-512 hash using SubtleCrypto
        const hashBuffer = await crypto.subtle.digest("SHA-512", data);

        // Convert the hash ArrayBuffer to a Base64 string
        const hashBase64 = arrayBufferToBase64(hashBuffer);

        return hashBase64;
    } catch (error) {
        console.error("Error hashing input:", error);
        throw error;
    }
};

export default hashSHA512;
